:root {
	--theme-primary-50: #eae3ea;
	--theme-primary-50-rgb: 234, 227, 234;
	--theme-primary-contrast-50: #ffffff;
	--theme-primary-contrast-50-rgb: 255, 255, 255;
	--theme-primary-100: #cbbacb;
	--theme-primary-100-rgb: 203, 186, 203;
	--theme-primary-contrast-100: #ffffff;
	--theme-primary-contrast-100-rgb: 255, 255, 255;
	--theme-primary-200: #a88ca8;
	--theme-primary-200-rgb: 168, 140, 168;
	--theme-primary-contrast-200: #ffffff;
	--theme-primary-contrast-200-rgb: 255, 255, 255;
	--theme-primary-300: #855d85;
	--theme-primary-300-rgb: 133, 93, 133;
	--theme-primary-contrast-300: #ffffff;
	--theme-primary-contrast-300-rgb: 255, 255, 255;
	--theme-primary-400: #6a3b6b;
	--theme-primary-400-rgb: 106, 59, 107;
	--theme-primary-contrast-400: #ffffff;
	--theme-primary-contrast-400-rgb: 255, 255, 255;
	--theme-primary-500: #501851;
	--theme-primary-500-rgb: 80, 24, 81;
	--theme-primary-contrast-500: #ffffff;
	--theme-primary-contrast-500-rgb: 255, 255, 255;
	--theme-primary-600: #49154a;
	--theme-primary-600-rgb: 73, 21, 74;
	--theme-primary-contrast-600: #ffffff;
	--theme-primary-contrast-600-rgb: 255, 255, 255;
	--theme-primary-700: #401140;
	--theme-primary-700-rgb: 64, 17, 64;
	--theme-primary-contrast-700: #ffffff;
	--theme-primary-contrast-700-rgb: 255, 255, 255;
	--theme-primary-800: #370e37;
	--theme-primary-800-rgb: 55, 14, 55;
	--theme-primary-contrast-800: #ffffff;
	--theme-primary-contrast-800-rgb: 255, 255, 255;
	--theme-primary-900: #270827;
	--theme-primary-900-rgb: 39, 8, 39;
	--theme-primary-contrast-900: #ffffff;
	--theme-primary-contrast-900-rgb: 255, 255, 255;
	--theme-primary-A100: #ce78ff;
	--theme-primary-A100-rgb: 206, 120, 255;
	--theme-primary-contrast-A100: #ffffff;
	--theme-primary-contrast-A100-rgb: 255, 255, 255;
	--theme-primary-A200: #bc45ff;
	--theme-primary-A200-rgb: 188, 69, 255;
	--theme-primary-contrast-A200: #ffffff;
	--theme-primary-contrast-A200-rgb: 255, 255, 255;
	--theme-primary-A400: #aa12ff;
	--theme-primary-A400-rgb: 170, 18, 255;
	--theme-primary-contrast-A400: #ffffff;
	--theme-primary-contrast-A400-rgb: 255, 255, 255;
	--theme-primary-A700: #9e00f7;
	--theme-primary-A700-rgb: 158, 0, 247;
	--theme-primary-contrast-A700: #ffffff;
	--theme-primary-contrast-A700-rgb: 255, 255, 255;
	--theme-warn-50: #f9e0e0;
	--theme-warn-50-rgb: 249, 224, 224;
	--theme-warn-contrast-50: #ffffff;
	--theme-warn-contrast-50-rgb: 255, 255, 255;
	--theme-warn-100: #f0b3b3;
	--theme-warn-100-rgb: 240, 179, 179;
	--theme-warn-contrast-100: #ffffff;
	--theme-warn-contrast-100-rgb: 255, 255, 255;
	--theme-warn-200: #e68080;
	--theme-warn-200-rgb: 230, 128, 128;
	--theme-warn-contrast-200: #ffffff;
	--theme-warn-contrast-200-rgb: 255, 255, 255;
	--theme-warn-300: #db4d4d;
	--theme-warn-300-rgb: 219, 77, 77;
	--theme-warn-contrast-300: #ffffff;
	--theme-warn-contrast-300-rgb: 255, 255, 255;
	--theme-warn-400: #d42626;
	--theme-warn-400-rgb: 212, 38, 38;
	--theme-warn-contrast-400: #ffffff;
	--theme-warn-contrast-400-rgb: 255, 255, 255;
	--theme-warn-500: #cc0000;
	--theme-warn-500-rgb: 204, 0, 0;
	--theme-warn-contrast-500: #ffffff;
	--theme-warn-contrast-500-rgb: 255, 255, 255;
	--theme-warn-600: #c70000;
	--theme-warn-600-rgb: 199, 0, 0;
	--theme-warn-contrast-600: #ffffff;
	--theme-warn-contrast-600-rgb: 255, 255, 255;
	--theme-warn-700: #c00000;
	--theme-warn-700-rgb: 192, 0, 0;
	--theme-warn-contrast-700: #ffffff;
	--theme-warn-contrast-700-rgb: 255, 255, 255;
	--theme-warn-800: #b90000;
	--theme-warn-800-rgb: 185, 0, 0;
	--theme-warn-contrast-800: #ffffff;
	--theme-warn-contrast-800-rgb: 255, 255, 255;
	--theme-warn-900: #ad0000;
	--theme-warn-900-rgb: 173, 0, 0;
	--theme-warn-contrast-900: #ffffff;
	--theme-warn-contrast-900-rgb: 255, 255, 255;
	--theme-warn-A100: #ffe6eb;
	--theme-warn-A100-rgb: 255, 230, 235;
	--theme-warn-contrast-A100: #ffffff;
	--theme-warn-contrast-A100-rgb: 255, 255, 255;
	--theme-warn-A200: #ffb3c2;
	--theme-warn-A200-rgb: 255, 179, 194;
	--theme-warn-contrast-A200: #ffffff;
	--theme-warn-contrast-A200-rgb: 255, 255, 255;
	--theme-warn-A400: #ff809a;
	--theme-warn-A400-rgb: 255, 128, 154;
	--theme-warn-contrast-A400: #ffffff;
	--theme-warn-contrast-A400-rgb: 255, 255, 255;
	--theme-warn-A700: #ff6785;
	--theme-warn-A700-rgb: 255, 103, 133;
	--theme-warn-contrast-A700: #ffffff;
	--theme-warn-contrast-A700-rgb: 255, 255, 255;
	--theme-accent-50: #eae3ea;
	--theme-accent-50-rgb: 234, 227, 234;
	--theme-accent-contrast-50: #ffffff;
	--theme-accent-contrast-50-rgb: 255, 255, 255;
	--theme-accent-100: #cbbacb;
	--theme-accent-100-rgb: 203, 186, 203;
	--theme-accent-contrast-100: #ffffff;
	--theme-accent-contrast-100-rgb: 255, 255, 255;
	--theme-accent-200: #a88ca8;
	--theme-accent-200-rgb: 168, 140, 168;
	--theme-accent-contrast-200: #ffffff;
	--theme-accent-contrast-200-rgb: 255, 255, 255;
	--theme-accent-300: #855d85;
	--theme-accent-300-rgb: 133, 93, 133;
	--theme-accent-contrast-300: #ffffff;
	--theme-accent-contrast-300-rgb: 255, 255, 255;
	--theme-accent-400: #6a3b6b;
	--theme-accent-400-rgb: 106, 59, 107;
	--theme-accent-contrast-400: #ffffff;
	--theme-accent-contrast-400-rgb: 255, 255, 255;
	--theme-accent-500: #501851;
	--theme-accent-500-rgb: 80, 24, 81;
	--theme-accent-contrast-500: #ffffff;
	--theme-accent-contrast-500-rgb: 255, 255, 255;
	--theme-accent-600: #49154a;
	--theme-accent-600-rgb: 73, 21, 74;
	--theme-accent-contrast-600: #ffffff;
	--theme-accent-contrast-600-rgb: 255, 255, 255;
	--theme-accent-700: #401140;
	--theme-accent-700-rgb: 64, 17, 64;
	--theme-accent-contrast-700: #ffffff;
	--theme-accent-contrast-700-rgb: 255, 255, 255;
	--theme-accent-800: #370e37;
	--theme-accent-800-rgb: 55, 14, 55;
	--theme-accent-contrast-800: #ffffff;
	--theme-accent-contrast-800-rgb: 255, 255, 255;
	--theme-accent-900: #270827;
	--theme-accent-900-rgb: 39, 8, 39;
	--theme-accent-contrast-900: #ffffff;
	--theme-accent-contrast-900-rgb: 255, 255, 255;
	--theme-accent-A100: #ce78ff;
	--theme-accent-A100-rgb: 206, 120, 255;
	--theme-accent-contrast-A100: #ffffff;
	--theme-accent-contrast-A100-rgb: 255, 255, 255;
	--theme-accent-A200: #bc45ff;
	--theme-accent-A200-rgb: 188, 69, 255;
	--theme-accent-contrast-A200: #ffffff;
	--theme-accent-contrast-A200-rgb: 255, 255, 255;
	--theme-accent-A400: #aa12ff;
	--theme-accent-A400-rgb: 170, 18, 255;
	--theme-accent-contrast-A400: #ffffff;
	--theme-accent-contrast-A400-rgb: 255, 255, 255;
	--theme-accent-A700: #9e00f7;
	--theme-accent-A700-rgb: 158, 0, 247;
	--theme-accent-contrast-A700: #ffffff;
	--theme-accent-contrast-A700-rgb: 255, 255, 255;

}
